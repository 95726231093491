
import axios from "./axios.js";

import {setLoadRecord, setRecord} from "../store/main";

export function saveRecord(dispatch, {userId, record}) {
    return axios
        .post('/api/misc/setRecord', {userId, record})
        .then(response => {
            return response;
        })
        .catch(err => {
            console.log('saveRecord err: ', err)
            return err
        })
        .finally( () => {
        })
}

export function getRecord(dispatch) {
    dispatch(setLoadRecord(true));
    return axios
        .get('/api/misc/getRecord')
        .then(response => {
            dispatch(setRecord(response.data.record));
            return response;
        })
        .catch( err => {
            console.log('getRecord err: ', err)
            return err
        })
        .finally( () => {
            dispatch(setLoadRecord(false));
        })
}
