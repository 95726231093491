import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  getRecord
} from '../utils/api'

function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadRecord = useSelector((state: any) => state.main.loadRecord);
  const record = useSelector((state: any) => state.main.record);

  // const [record, setRecodr] = useState(0);

  useEffect(() => {
    !loadRecord && getRecord(dispatch);
  }, []);

  function goTo(path: string) {
    navigate(path)
  }

  return (
    <>
      <div className="center">
        <div className="items">
          <div className="boarderItem hoverCursor" onClick={ () => {goTo('play')}}>
            <div className="textmiddle">{"Play game"}</div>
          </div>
          <div className="boarderItem">
            <div className="textmiddle">{"High scores " + record}</div>
          </div>
        </div>
      </div>
      <footer>
        <img src="./img/jokerLeft.webp" alt="joker" />
        <img src="./img/clownRight.webp" alt="clown" className='clownRight'/>
      </footer>
    </>
  );
}

export default Main;
