import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools }                           from "redux-devtools-extension";
import mainReducer                                     from "./main";
import thunk                                             from "redux-thunk";

/**
 * Logs all actions and states after they are dispatched.
 */
 const logger = (store: any) => (next: any) => (action: any) => {
    // console.group(action.type)
    // console.info('dispatching', action)
    // console.log('next state', store.getState())
    // console.groupEnd()
    return next(action)
}

const rootReducer = combineReducers({
    main: mainReducer,
})
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)));
export default store
