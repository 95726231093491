
import axios_ from 'axios';

// @ts-ignore
const axios = axios_.create({
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    // baseURL: process.env.NODE_ENV === 'development' ? 'https://.com/' : '',
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4380' : '',
    withCredentials: true,
})

export default axios;
