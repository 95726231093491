import React from 'react';
import { Outlet } from 'react-router-dom';

function AppContainer() {
  return (
    <div className="App">
      <div className="fullScreeen">
        <div className="bg"></div>
        <div className="bg bgMirrored"></div>
        <div className="bg"></div>
        <div className="bg bgMirrored"></div>
        <div className="bg"></div>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

export default AppContainer;
