
const SET_LOAD_RECORD = 'SET_LOAD_RECORD'
const SET_RECORD = 'SET_RECORD'

const defaultState = {
    loadRecord: false,
    record: 0,
}

export default function globalReducer(state = defaultState, action: any) {
    switch (action.type) {
        case SET_LOAD_RECORD:
            return {
                ...state,
                loadRecord: action.payload
            }
        case SET_RECORD:
            return {
                ...state,
                record: action.payload
            }
        default:
            return state;
    }
}

export const setLoadRecord = (st: boolean) => ({type: SET_LOAD_RECORD, payload: st})
export const setRecord = (val: number) => ({type: SET_RECORD, payload: val})
