import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
  saveRecord
} from '../utils/api'

type IObj = {
  [key: string]: string
}
const gameItems = ['a', 'j', 'q', 'bell', 'star'];

const gameItemsIcons: IObj = {
  a: 'A.webp',
  j: 'J.webp',
  q: 'Q.webp',
  bell: 'bell.webp',
  star: 'star.webp',
};

const arrLength = 20;

function getRandomEl() {
  return gameItems[Math.floor(Math.random() * 5)];
}
function getRandomArray() {
  let i = 0;
  const ar = []
  while (i < arrLength) {
    ar.push(getRandomEl());
    i++;
  };
  return ar;
}

function isLineNumber(n: number) {
  return Math.floor(n/5);
}

function Play() {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState('');
  const [arr, setArr] = useState(getRandomArray());
  const [score, setScore] = useState(0);
  const [moveCard, setMoveCard] = useState<{el: null | string, i: null | number}>({el: null, i: null});

  const containerRef = useRef();

  function isOneLine(index: number): number[] {
    const line = isLineNumber(index);
    const line1 = isLineNumber(index+1);
    const line2 = isLineNumber(index+2);
    if(arr[index] === arr[index + 1] &&
      arr[index] === arr[index + 2] && 
      line === line1 &&
      line === line2
    ) return [index, index+1, index+2];

    const line5 = isLineNumber(index+5);
    const line10 = isLineNumber(index+10);
    if(arr[index] === arr[index + 5] &&
      arr[index] === arr[index + 10] && 
      line === line5-1 &&
      line === line10-2
    ) return [index, index+5, index+10];

    const line4 = isLineNumber(index+4);
    const line8 = isLineNumber(index+8);
    if(arr[index] === arr[index + 4] &&
      arr[index] === arr[index + 8] && 
      line === line4-1 &&
      line === line8-2
    ) return [index, index+4, index+8];

    const line6 = isLineNumber(index+6);
    const line12 = isLineNumber(index+12);
    if(arr[index] === arr[index + 6] &&
      arr[index] === arr[index + 12] && 
      line === line6-1 &&
      line === line12-2
    ) return [index, index+6, index+12];
    return [];
  }

  useEffect(() => {
    const userId_ = localStorage.getItem('userId');
    const d = 'userId' + Date.now();
    if(!userId_) localStorage.setItem('userId', d);
    setUserId(userId_ || d)
  }, []);

  useEffect(() => {
    let i = 0;
    const arChengeIndex: number[] = [];
    while (i < arrLength) {
      const indexesToChange = isOneLine(i);
      indexesToChange.forEach((ind) => {
        !arChengeIndex.includes(ind) && arChengeIndex.push(ind);
      });
      i++;
    };
    if (!arChengeIndex.length) return;
    setScore(score + arChengeIndex.length*100);
    const newArr = [...arr];
    arChengeIndex.forEach((ind) => {
      newArr[ind] = getRandomEl();
    });
    setArr(newArr);
  }, [arr]);

  useEffect(() => {
    score && saveRecord(dispatch, {userId, record: score});
  }, [score]);

  function start(e: any, i: number, el: string) {
    setMoveCard({el, i})
  };
  function over(e: any, i: number, el: string) {
    e.preventDefault();
  };
  function drop(e: any, i: number, el: string) {
    if(!moveCard.el) return;
    if(moveCard.i === i+4 ||
      moveCard.i === i+5 ||
      moveCard.i === i+6 ||
      moveCard.i === i+1 ||
      moveCard.i === i-1 ||
      moveCard.i === i-4 ||
      moveCard.i === i-5 ||
      moveCard.i === i-6
    ) {
      const newArr = [...arr];
      newArr[i] = moveCard.el as string;
      newArr[moveCard.i as number] = el;
      setArr(newArr);
      setMoveCard({el: null, i: null});
    }
  };

  function detectLeftBtn(e: any) {
    const ev: any = e || window.event;
    if('buttons' in ev) {
      return ev.buttons === 1;
    }
    const button = e.which || e.button;
    return button === 1;
  }

  function startMob(e: any, index: number, el: string) {
    if(!detectLeftBtn(e)) return;
    const container: any = containerRef.current;
    const items = [...container.childNodes];
    const dragItem = items[index];
    const boundingClientRect = dragItem.getBoundingClientRect();
    let div: any = document.createElement("div");
    div.className = 'item';
    div.style.position = 'fixed';
    div.style.width = boundingClientRect.width + 'px';
    div.style.height = boundingClientRect.height + 'px';
    div.style.top = boundingClientRect.top + 'px';
    div.style.left = boundingClientRect.left + 'px';

    const img = document.createElement('img');
    img.draggable = true;
    img.src = "./img/gameItems/" + gameItemsIcons[el];
    img.className = 'moveCursor';
    div.appendChild(img);
    container.appendChild(div);

    const x = e.clientX;
    const y = e.clientY;
    const gameField: any = document.getElementById('gameField');
    gameField.onpointermove = dragMove;
    gameField.onpointerup = dropMob;

    function dragMove(e: any) {
      const posX = e.clientX - x;
      const posY = e.clientY - y;
      div.style.transform = `translate(${posX}px, ${posY}px)`;
    };
    function dropMob(e: any) {
      if(!el) return;

      items.forEach( (item, i) => {
        const rect1 = div.getBoundingClientRect();
        const rect2 = item.getBoundingClientRect();
        const isOverlapping = rect1.y < rect2.y + (rect2.height/2) &&
          rect1.y + (rect1.height/2) > rect2.y &&
          rect1.x < rect2.x + (rect2.height/2) &&
          rect1.x + (rect1.height/2) > rect2.x;
          if(isOverlapping && index !== i) {
            if(index === i+4 ||
              index === i+5 ||
              index === i+6 ||
              index === i+1 ||
              index === i-1 ||
              index === i-4 ||
              index === i-5 ||
              index === i-6
            ) {
              const newArr = [...arr];
              newArr[i] = el as string;
              newArr[index as number] = arr[i];
              setArr(newArr);
              setMoveCard({el: null, i: null});
            }
          }
      });

      container.removeChild(div);
      div = null;
      gameField.onpointermove = null;
      gameField.onpointerup = null;
    };
  };
 
  return (
    <>
      <div className="center">
        <div className="boarderItem">
          <div className="textmiddle">{"SCORE: " + score}</div>
        </div>
        <div className="gameField"
          // @ts-ignore
          ref={containerRef}
          id={'gameField'}
        >
          {arr.map( (el: string, i: number) => {
            return <div className="item" key={i}>
              <img
                draggable={true}

                onPointerDown={(e) => {startMob(e, i, el)}}
                onDragStart={() => false}

                src={"./img/gameItems/" + gameItemsIcons[el]}
                alt=""
                className={'moveCursor'}
              />
            </div>
          })}
        </div>
      </div>
      <footer>
        <img src="./img/clownLeft.webp" alt="clown" className='clownLeft'/>
        <img src="./img/jokerRight.webp" alt="joker" className='jokerRight' />
      </footer>
    </>
  );
}

export default Play;
