import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';

import logo from './static/logo.svg';

import './styles/App.scss';
import AppContainer from './components/AppContainer';
import Main from './components/Main';
import Play from './components/Play';

const Router = ({ children }: {children: any}) => {
  const isProduction = process.env.NODE_ENV === 'production';

  if (isProduction) return <HashRouter>{children}</HashRouter>;

  return <BrowserRouter>{children}</BrowserRouter>;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppContainer />} >
            <Route index element={<Main />} />
            <Route path="play" element={<Play />} />
          </Route>
      </Routes>
    </Router>
  );
}

export default App;
